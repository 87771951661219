import { LuCheckCircle } from "react-icons/lu";
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { FaArrowLeft } from "react-icons/fa";

import globalBackground from "../../Images/global-background.png";
import welcomeToTheBiz from "../../Images/welcome-to-the-biz.png";

export default function FormSubmitted() {
  const { state } = useLocation();
  const data = state?.userName || '';

  console.log(data);

  return (
    <main className='h-screen w-full bg-violet-500 text-center md:pb-20' style={{ touchAction: "pan-x pan-y", maxHeight: "100dvh", overflow: "hidden" }}>
      <div className='h-full flex flex-col justify-center p-8 sm:max-w-7xl mx-auto'>
        <h1 className='flex relative justify-center items-center gap-2 text-2xl sm:text-4xl font-extrabold text-white md:bottom-40 mt-5' style={{ zIndex: 10 }}>
          <span className="inline">
            Thanks {data}, we have received your <span className='inline'>inquiry <LuCheckCircle className='inline bottom-0 right-2' /></span>
          </span>
        </h1>
        <div className='flex flex-col justify-end mt-5 md:mt-0 max-h-screen md:justify-center md:max-h-80 md:items-center'>
          <img src={welcomeToTheBiz} alt="Welcome to the biz" className='relative z-10 sm:w-2/5 md:top-60' style={{ zIndex: 10 }}></img>
          <i className='text-white font-bold lg:text-3xl md:text-2xl mt-10 md:relative md:top-60' style={{ zIndex: 10 }}>Someone from our team at Fulfill will reach out to you soon via your preferred contact method.</i>
          <img src={globalBackground} alt="a world globe page background" className="z-0 relative bottom-1/4 md:static md:mx-w-2/4" style={{ zIndex: 0 }}></img>
          <Link
            to="/"
            className={`relative bottom-10 md:bottom-40 flex items-center gap-2 w-auto cursor-pointer text-xl text-center font-bold text-white bg-black shadow-md hover:text-gray-70 px-8 py-2 rounded-full`}
            style={{ zIndex: 10 }}
          >
            <FaArrowLeft />
            Take me back to fulfill
          </Link>
        </div>
      </div>
    </main>
  )
}