import RequiredMark from "./RequiredMark";
import React, { useState, useRef, useEffect } from 'react';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import './formTelInputStyle.css'

export default function FormTelInput({ placeholder, name, identifier, required, className, errorMessage }) {
  const [value, setValue] = useState();
  const [showFakePlaceholder, setShowFakePlaceholder] = useState(true);

  const fakePlaceholderRef = useRef(null);
  const inputRef = useRef(null);

  useEffect(() => {
    const flagContainer = document.querySelector(".selected-flag");
    const flagDiv = document.querySelector(".selected-flag > div");
    const countryLabel = document.createElement("p");
    countryLabel.className = "country-label";
    countryLabel.style.position = "absolute";
    countryLabel.style.top = "40%";
    countryLabel.style.right = "20px";
    countryLabel.style.marginTop = "-5px";
    countryLabel.style.fontSize = "14px";
    countryLabel.style.color = "rgba(156, 163, 175)";
    countryLabel.innerText = "Country";

    countryLabel.addEventListener("mouseenter", () => {
      countryLabel.style.color = "#000000";
    });
    countryLabel.addEventListener("mouseleave", () => {
      countryLabel.style.color = "rgba(156, 163, 175)";
    })

    if (flagDiv.classList.contains("0") && !flagContainer.querySelector(".country-label")) {
      flagContainer.appendChild(countryLabel);
    } else {
      const countryLabelChild = document.querySelector(".country-label");
      countryLabelChild && flagContainer.removeChild(countryLabelChild);
    }
  });

  function onInputFocus(event, data) {
    if (showFakePlaceholder) {
      setShowFakePlaceholder(false);
    }
  }

  function onInputBlur(event, data) {
    if (event.target.value.length <= 1) {
      setShowFakePlaceholder(true);
    }
  }

  function handleFakePlaceholderClick() {
    inputRef && inputRef.current.focus();
  }

  return (
    <div>
      <div
        className={`${className} relative inline-flex w-full justify-between bg-white border border-gray-300 rounded-md shadow-sm focus-within:ring-2 focus-within:ring-indigo-500`}
      >
        <PhoneInput
          country={'us'}
          containerStyle={{ fontSize: "16px", borderStyle: "none", width: "100%", outlineStyle: "none" }}
          inputStyle={{ borderStyle: 'none', width: "100%", fontSize: "16px", height: "40px", outlineStyle: "none" }}
          value={value}
          onChange={setValue}
          dropdownStyle={{ zIndex: "30" }}
          placeholder="Phone Number"
          inputProps={{
            name: 'phone',
            ref: inputRef,
          }}
          onFocus={onInputFocus}
          onBlur={onInputBlur}
        />
        {showFakePlaceholder &&
          <div
            ref={fakePlaceholderRef}
            className="absolute bg-white top-2 left-4 text-gray-400 text-base font-medium w-2/4"
            onClick={handleFakePlaceholderClick}
          >
            Phone Number
          </div>
        }
        {required && <RequiredMark className="absolute -right-2 -top-2 z-10" />}
      </div>
      {errorMessage && <p className="text-red-600 text-sm mt-0.5">{errorMessage}</p>}
    </div>
  )
}