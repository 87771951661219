import React, { useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { FaArrowRight } from "react-icons/fa6";
import RequiredMark from "./RequiredMark";
import MultiSelectDropdown from "./FormMultiSelect";
import FormInput from './FormInput';
import SelectDropdown from './FormSelect';
import FileInput from './FormFileInput';
import FormTelInput from "./FormTelInput";
import FormTextArea from './FormTextArea';

const serviceTypes = [
  { value: 'Dropshipping', label: 'Dropshipping' },
  { value: 'Bulk Orders', label: 'Bulk Orders' },
  { value: 'Sourcing & Fulfillment', label: 'Sourcing & Fulfillment' },
  { value: 'Custom Content Creation', label: 'Custom Content Creation' },
  { value: 'Custom Packaging', label: 'Custom Packaging' },
  { value: 'Private Labeling & White Labeling', label: 'Private Labeling & White Labeling' },
  { value: 'Product Development', label: 'Product Development' },
];

const monthlyRevenue = [
  { value: '$10-30k', label: '$10-30k' },
  { value: '$30-50k', label: '$30-50k' },
  { value: '$100k-1M', label: '$100k-1M' },
  { value: '$1M+', label: '$1M+' },
]

const contactMethods = [
  { value: 'Email', label: 'Email' },
  { value: 'Text Message / iMessage', label: 'Text Message / iMessage' },
  { value: 'WhatsApp', label: 'WhatsApp' },
]

const hearAbout = [
  { value: 'A Friend', label: 'A Friend' },
  { value: 'Instagram', label: 'Instagram' },
  { value: 'YouTube', label: 'YouTube' },
  { value: 'Surfing the web', label: 'Surfing the web' },
  { value: 'Email', label: 'Email' },
  { value: 'Other', label: 'Other' },
]


export default function ContactForm() {
  const navigate = useHistory();

  const [selectedServiceTypes, setSelectedServiceTypes] = useState([]);
  const [selectedMonthlyRevenue, setSelectedMonthlyRevenue] = useState("");
  const [selectedContactMethod, setSelectedContactMethod] = useState("");
  const [selectedHearAbout, setSelectedHearAbout] = useState("");

  const [files, setFiles] = useState([]);

  const [nameError, setNameError] = useState("");
  const [brandError, setBrandError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [websiteError, setWebsiteError] = useState("");
  const [selectedServiceError, setSelectedServiceError] = useState("");

  const nameInputRef = useRef(null);
  const emailInputRef = useRef(null);
  const brandInputRef = useRef(null);
  const websiteInputRef = useRef(null);
  const formRef = useRef(null);

  const inputRefs = [
    { ref: nameInputRef, set: setNameError, hasError: false },
    { ref: emailInputRef, set: setEmailError, hasError: false },
    { ref: brandInputRef, set: setBrandError, hasError: false },
    { ref: websiteInputRef, set: setWebsiteError, hasError: false }
  ];

  const handleFileChange = (fileList) => {
    // Converte o FileList para um array e atualiza o estado
    setFiles(Array.from(fileList));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    let formIsValid = false;
    let selectedServiceIsValid = false;

    if (selectedServiceTypes.length <= 0) {
      setSelectedServiceError("Please select at least one option");
    } else {
      setSelectedServiceError("");
      selectedServiceIsValid = true;
    }

    inputRefs.forEach((input) => {
      if (input.ref.current.value.trim() === '' || !input.ref.current.checkValidity()) {
        let inputMessage;

        switch (input.ref.current.name) {
          case "url":
            inputMessage = input.ref.current.name.toUpperCase();
            break;
          case "brand":
            inputMessage = `${input.ref.current.name} name`;
            break;
          default:
            inputMessage = input.ref.current.name;
            break;
        }

        if (input.ref.current.name !== "url") {
          input.set(`Please enter a valid ${inputMessage}`);
          input.hasError = true;
        }
      } else {
        input.set("");
        input.hasError = false;
      }
    });

    formIsValid = selectedServiceIsValid && !inputRefs.find((input => input.hasError === true));

    if (formIsValid) {
      var formData = new FormData(formRef.current);
      var url = "https://formspree.io/f/mnnaqzye";

      formData.set("services", selectedServiceTypes.toString());
      formData.set("revenue", selectedMonthlyRevenue);
      formData.set("contact method", selectedContactMethod);
      formData.set("heard about", selectedHearAbout);

      fetch(url, {
        method: 'POST',
        headers: {
          'Accept': 'application/json'
        },
        body: formData,
      })
        .then(response => response.json())
        .then(data => {
          console.log('Success:', data);
          const userName = nameInputRef.current.value;
          navigate.push('/form-submitted', { userName });
        })
        .catch(error => {
          console.error('Error:', error);
        });
    }

  };

  return (
    <form className="w-full" ref={formRef} method="POST" encType="multipart/form-data" style={{ touchAction: "pan-x pan-y" }}>
      <div>
        <label className="font-bold text-xl flex items-center gap-2 mb-2">Type of Service <RequiredMark /></label>
        <MultiSelectDropdown name="services" identifier="services"
          errorMessage={selectedServiceError}
          selectedOptions={selectedServiceTypes}
          onChange={setSelectedServiceTypes} options={serviceTypes}
        />
      </div>
      <div className='mt-8 z-0'>
        <label className="font-bold text-xl flex items-center gap-1 mb-2">Contact Details</label>
        <FormInput placeholder="Your name" type={"text"} name={"name"} identifier={"name"} required={true} errorMessage={nameError} refElement={nameInputRef} />
        <FormInput placeholder="Brand name or company name" type={"text"} name={"brand"} identifier={"brand"} required={true} className="mt-3" errorMessage={brandError} refElement={brandInputRef} />
        <FormInput placeholder="Your e-mail" name={"email"} type={"email"} identifier={"email"} required={true} className="mt-3" errorMessage={emailError} refElement={emailInputRef} />
        <FormTelInput placeholder="Phone Number" className="mt-3" name="phone" identifier={"phone"} required={false}></FormTelInput>
        <FormInput placeholder="Your website or product URL" type={"url"} name={"url"} identifier={"url"} required={false} className="mt-3" errorMessage={websiteError} refElement={websiteInputRef} />
      </div>

      <div className='mt-8 relative z-0' style={{ touchAction: "pan-x pan-y" }}>
        <label className='font-bold text-xl flex items-center gap-1 mb-3'>Business Specifics</label>
        <SelectDropdown name="revenue" id="revenue"
          selectedOption={selectedMonthlyRevenue}
          onChange={setSelectedMonthlyRevenue}
          options={monthlyRevenue}
          placeholder={"Current monthly revenue"}
          className={"z-10"}
        />
        <FileInput onChange={handleFileChange} value={files} className="mt-3 mb-3 z-0" name={"attachment"} identifier={"attachment"} />
        <FormTextArea placeholder="Feel free to share anything you want with us.
          What you're looking for, and any further details about the product or the service we can provide you with."
          name={"message"}
          identifier={"message"}
        />
      </div>
      <div className='mt-8'>
        <label className='font-bold text-xl flex items-center gap-1 mb-3'>Preferred Contact Method?</label>
        <SelectDropdown name="contact-method" id="contact-method"
          selectedOption={selectedContactMethod}
          onChange={setSelectedContactMethod}
          options={contactMethods}
          placeholder={"Select a option"}
          className={"z-10"}
        />
        <label className='mt-6 font-bold text-xl flex items-center gap-1 mb-3'>How Did You Hear About Us?</label>
        <SelectDropdown name="heard-about" id="heard-about"
          selectedOption={selectedHearAbout}
          onChange={setSelectedHearAbout}
          options={hearAbout}
          placeholder={"Select a option"}
          className={"z-0"}
        />
      </div>

      <button
        type='button'
        onClick={handleSubmit}
        className="ml-2 mt-8 whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-violet-500 hover:bg-violet-600"
      >
        Submit
        <FaArrowRight className='ml-1' size={18}></FaArrowRight>
      </button>
    </form>
  )
}