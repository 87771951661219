import React, { useRef } from "react";
import VoiceMessageWidget from "../VoiceMessageWidget/VoiceMessageWidget";
import "./videoMessageWidget.css"

function VideoMessageWidget({ src, poster }) {
  let videoRef = useRef();

  const toggleVideoPlay = ({ current: video }) => {
    if (video.paused) {
      return video.play();
    }
    video.pause();
  };

  return (
    <>
      <video className="relative"
        disablePictureInPicture
        disableRemotePlayback
        ref={videoRef}
        src={src} poster={poster}
        playsInline
        preload="auto"
      >
      </video>
      <VoiceMessageWidget src={src} options={{ interact: false }} video={true} clickAction={() => toggleVideoPlay(videoRef)} />
    </>
  );
}

export default VideoMessageWidget;